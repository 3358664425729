import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const Customer = () => import('@/entities/customer/customer.vue');
// prettier-ignore
const CustomerUpdate = () => import('@/entities/customer/customer-update.vue');
// prettier-ignore
const CustomerDetails = () => import('@/entities/customer/customer-details.vue');
// prettier-ignore
const Animal = () => import('@/entities/animal/animal.vue');
// prettier-ignore
const AnimalUpdate = () => import('@/entities/animal/animal-update.vue');
// prettier-ignore
const AnimalDetails = () => import('@/entities/animal/animal-details.vue');
// prettier-ignore
const Contract = () => import('@/entities/contract/contract.vue');
// prettier-ignore
const ContractUpdate = () => import('@/entities/contract/contract-update.vue');
// prettier-ignore
const ContractDetails = () => import('@/entities/contract/contract-details.vue');
// prettier-ignore
const Claim = () => import('@/entities/claim/claim.vue');
// prettier-ignore
const ClaimUpdate = () => import('@/entities/claim/claim-update.vue');
// prettier-ignore
const ClaimDetails = () => import('@/entities/claim/claim-details.vue');
// prettier-ignore
const Claimant = () => import('@/entities/claimant/claimant.vue');
// prettier-ignore
const ClaimantUpdate = () => import('@/entities/claimant/claimant-update.vue');
// prettier-ignore
const ClaimantDetails = () => import('@/entities/claimant/claimant-details.vue');
// prettier-ignore
const ClaimDocument = () => import('@/entities/claim-document/claim-document.vue');
// prettier-ignore
const ClaimDocumentUpdate = () => import('@/entities/claim-document/claim-document-update.vue');
// prettier-ignore
const ClaimDocumentDetails = () => import('@/entities/claim-document/claim-document-details.vue');
// prettier-ignore
const ContractDocument = () => import('@/entities/contract-document/contract-document.vue');
// prettier-ignore
const ContractDocumentUpdate = () => import('@/entities/contract-document/contract-document-update.vue');
// prettier-ignore
const ContractDocumentDetails = () => import('@/entities/contract-document/contract-document-details.vue');
// prettier-ignore
const AnimalImage = () => import('@/entities/animal-image/animal-image.vue');
// prettier-ignore
const AnimalImageUpdate = () => import('@/entities/animal-image/animal-image-update.vue');
// prettier-ignore
const AnimalImageDetails = () => import('@/entities/animal-image/animal-image-details.vue');
// prettier-ignore
const Agent = () => import('@/entities/agent/agent.vue');
// prettier-ignore
const AgentUpdate = () => import('@/entities/agent/agent-update.vue');
// prettier-ignore
const AgentDetails = () => import('@/entities/agent/agent-details.vue');
// prettier-ignore
const Product = () => import('@/entities/product/product.vue');
// prettier-ignore
const ProductUpdate = () => import('@/entities/product/product-update.vue');
// prettier-ignore
const ProductDetails = () => import('@/entities/product/product-details.vue');
// prettier-ignore
const Modul = () => import('@/entities/modul/modul.vue');
// prettier-ignore
const ModulUpdate = () => import('@/entities/modul/modul-update.vue');
// prettier-ignore
const ModulDetails = () => import('@/entities/modul/modul-details.vue');
// prettier-ignore
const ModulVersion = () => import('@/entities/modul-version/modul-version.vue');
// prettier-ignore
const ModulVersionUpdate = () => import('@/entities/modul-version/modul-version-update.vue');
// prettier-ignore
const ModulVersionDetails = () => import('@/entities/modul-version/modul-version-details.vue');
// prettier-ignore
const ProductCode = () => import('@/entities/product-code/product-code.vue');
// prettier-ignore
const ProductCodeUpdate = () => import('@/entities/product-code/product-code-update.vue');
// prettier-ignore
const ProductCodeDetails = () => import('@/entities/product-code/product-code-details.vue');
// prettier-ignore
const VertragsNr = () => import('@/entities/vertrags-nr/vertrags-nr.vue');
// prettier-ignore
const VertragsNrDetails = () => import('@/entities/vertrags-nr/vertrags-nr-details.vue');
// prettier-ignore
const ImportRound = () => import('@/entities/import-round/import-round.vue');
// prettier-ignore
const ImportRoundDetails = () => import('@/entities/import-round/import-round-details.vue');
// prettier-ignore
const ImportError = () => import('@/entities/import-error/import-error.vue');
// prettier-ignore
const ImportErrorDetails = () => import('@/entities/import-error/import-error-details.vue');
// prettier-ignore
const UploadError = () => import('@/entities/upload-error/upload-error.vue');
// prettier-ignore
const UploadErrorDetails = () => import('@/entities/upload-error/upload-error-details.vue');
// prettier-ignore
const SchadenfreiRabatt = () => import('@/entities/schadenfrei-rabatt/schadenfrei-rabatt.vue');
// prettier-ignore
const SchadenfreiRabattUpdate = () => import('@/entities/schadenfrei-rabatt/schadenfrei-rabatt-update.vue');
// prettier-ignore
const SchadenfreiRabattDetails = () => import('@/entities/schadenfrei-rabatt/schadenfrei-rabatt-details.vue');
// prettier-ignore
const SchadenfreiRabattContract = () => import('@/entities/schadenfrei-rabatt-contract/schadenfrei-rabatt-contract.vue');
// prettier-ignore
const SchadenfreiRabattContractUpdate = () => import('@/entities/schadenfrei-rabatt-contract/schadenfrei-rabatt-contract-update.vue');
// prettier-ignore
const SchadenfreiRabattContractDetails = () => import('@/entities/schadenfrei-rabatt-contract/schadenfrei-rabatt-contract-details.vue');
// prettier-ignore
const SvsAgentur = () => import('@/entities/svs-agentur/svs-agentur.vue');
// prettier-ignore
const SvsAgenturUpdate = () => import('@/entities/svs-agentur/svs-agentur-update.vue');
// prettier-ignore
const SvsAgenturDetails = () => import('@/entities/svs-agentur/svs-agentur-details.vue');
// prettier-ignore
const Notification = () => import('@/entities/notification/notification.vue');
// prettier-ignore
const NotificationUpdate = () => import('@/entities/notification/notification-update.vue');
// prettier-ignore
const NotificationDetails = () => import('@/entities/notification/notification-details.vue');
// prettier-ignore
const Address = () => import('@/entities/address/address.vue');
// prettier-ignore
const AddressUpdate = () => import('@/entities/address/address-update.vue');
// prettier-ignore
const AddressDetails = () => import('@/entities/address/address-details.vue');
// prettier-ignore
const SvsMakler = () => import('@/entities/svs-makler/svs-makler.vue');
// prettier-ignore
const SvsMaklerDetails = () => import('@/entities/svs-makler/svs-makler-details.vue');
// prettier-ignore
const LinkAddress = () => import('@/entities/link-address/link-address.vue');
// prettier-ignore
const LinkAddressUpdate = () => import('@/entities/link-address/link-address-update.vue');
// prettier-ignore
const LinkAddressDetails = () => import('@/entities/link-address/link-address-details.vue');
// prettier-ignore
const ClaimCheck = () => import('@/entities/claim-check/claim-check.vue');
// prettier-ignore
const ClaimCheckUpdate = () => import('@/entities/claim-check/claim-check-update.vue');
// prettier-ignore
const ClaimCheckDetails = () => import('@/entities/claim-check/claim-check-details.vue');
// prettier-ignore
const Upgrade = () => import('@/entities/upgrade/upgrade.vue');
// prettier-ignore
const UpgradeUpdate = () => import('@/entities/upgrade/upgrade-update.vue');
// prettier-ignore
const UpgradeDetails = () => import('@/entities/upgrade/upgrade-details.vue');
// prettier-ignore
const Message = () => import('@/entities/message/message.vue');
// prettier-ignore
const MessageUpdate = () => import('@/entities/message/message-update.vue');
// prettier-ignore
const MessageDetails = () => import('@/entities/message/message-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

const ImportRoundDetailsExtended = () => import('@/entities/import-round/import-round-details-extended.vue');

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'customer',
      name: 'Customer',
      component: Customer,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'customer/new',
      name: 'CustomerCreate',
      component: CustomerUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'customer/:customerId/edit',
      name: 'CustomerEdit',
      component: CustomerUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'customer/:customerId/view',
      name: 'CustomerView',
      component: CustomerDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'animal',
      name: 'Animal',
      component: Animal,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'animal/new',
      name: 'AnimalCreate',
      component: AnimalUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'animal/:animalId/edit',
      name: 'AnimalEdit',
      component: AnimalUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'animal/:animalId/view',
      name: 'AnimalView',
      component: AnimalDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'contract',
      name: 'Contract',
      component: Contract,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'contract/new',
      name: 'ContractCreate',
      component: ContractUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'contract/:contractId/edit',
      name: 'ContractEdit',
      component: ContractUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'contract/:contractId/view',
      name: 'ContractView',
      component: ContractDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'claim',
      name: 'Claim',
      component: Claim,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'claim/new',
      name: 'ClaimCreate',
      component: ClaimUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'claim/:claimId/edit',
      name: 'ClaimEdit',
      component: ClaimUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'claim/:claimId/view',
      name: 'ClaimView',
      component: ClaimDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'claimant',
      name: 'Claimant',
      component: Claimant,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'claimant/new',
      name: 'ClaimantCreate',
      component: ClaimantUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'claimant/:claimantId/edit',
      name: 'ClaimantEdit',
      component: ClaimantUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'claimant/:claimantId/view',
      name: 'ClaimantView',
      component: ClaimantDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'claim-document',
      name: 'ClaimDocument',
      component: ClaimDocument,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'claim-document/new',
      name: 'ClaimDocumentCreate',
      component: ClaimDocumentUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'claim-document/:claimDocumentId/edit',
      name: 'ClaimDocumentEdit',
      component: ClaimDocumentUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'claim-document/:claimDocumentId/view',
      name: 'ClaimDocumentView',
      component: ClaimDocumentDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'contract-document',
      name: 'ContractDocument',
      component: ContractDocument,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'contract-document/new',
      name: 'ContractDocumentCreate',
      component: ContractDocumentUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'contract-document/:contractDocumentId/edit',
      name: 'ContractDocumentEdit',
      component: ContractDocumentUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'contract-document/:contractDocumentId/view',
      name: 'ContractDocumentView',
      component: ContractDocumentDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'animal-image',
      name: 'AnimalImage',
      component: AnimalImage,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'animal-image/new',
      name: 'AnimalImageCreate',
      component: AnimalImageUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'animal-image/:animalImageId/edit',
      name: 'AnimalImageEdit',
      component: AnimalImageUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'animal-image/:animalImageId/view',
      name: 'AnimalImageView',
      component: AnimalImageDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'agent',
      name: 'Agent',
      component: Agent,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'agent/new',
      name: 'AgentCreate',
      component: AgentUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'agent/:agentId/edit',
      name: 'AgentEdit',
      component: AgentUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'agent/:agentId/view',
      name: 'AgentView',
      component: AgentDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'product',
      name: 'Product',
      component: Product,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'product/new',
      name: 'ProductCreate',
      component: ProductUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'product/:productId/edit',
      name: 'ProductEdit',
      component: ProductUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'product/:productId/view',
      name: 'ProductView',
      component: ProductDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'modul',
      name: 'Modul',
      component: Modul,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'modul/new',
      name: 'ModulCreate',
      component: ModulUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'modul/:modulId/edit',
      name: 'ModulEdit',
      component: ModulUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'modul/:modulId/view',
      name: 'ModulView',
      component: ModulDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'modul-version',
      name: 'ModulVersion',
      component: ModulVersion,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'modul-version/new',
      name: 'ModulVersionCreate',
      component: ModulVersionUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'modul-version/:modulVersionId/edit',
      name: 'ModulVersionEdit',
      component: ModulVersionUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'modul-version/:modulVersionId/view',
      name: 'ModulVersionView',
      component: ModulVersionDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'product-code',
      name: 'ProductCode',
      component: ProductCode,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'product-code/new',
      name: 'ProductCodeCreate',
      component: ProductCodeUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'product-code/:productCodeId/edit',
      name: 'ProductCodeEdit',
      component: ProductCodeUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'product-code/:productCodeId/view',
      name: 'ProductCodeView',
      component: ProductCodeDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: '/vertrags-nr',
      name: 'VertragsNr',
      component: VertragsNr,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: '/vertrags-nr/:vertragsNrId/view',
      name: 'VertragsNrView',
      component: VertragsNrDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'import-round',
      name: 'ImportRound',
      component: ImportRound,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'import-round/:importRoundId/view',
      name: 'ImportRoundView',
      component: ImportRoundDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: '/import-error',
      name: 'ImportError',
      component: ImportError,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: '/import-error/:importErrorId/view',
      name: 'ImportErrorView',
      component: ImportErrorDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: '/upload-error',
      name: 'UploadError',
      component: UploadError,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: '/upload-error/:uploadErrorId/view',
      name: 'UploadErrorView',
      component: UploadErrorDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'schadenfrei-rabatt',
      name: 'SchadenfreiRabatt',
      component: SchadenfreiRabatt,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'schadenfrei-rabatt/new',
      name: 'SchadenfreiRabattCreate',
      component: SchadenfreiRabattUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'schadenfrei-rabatt/:schadenfreiRabattId/edit',
      name: 'SchadenfreiRabattEdit',
      component: SchadenfreiRabattUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'schadenfrei-rabatt/:schadenfreiRabattId/view',
      name: 'SchadenfreiRabattView',
      component: SchadenfreiRabattDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'schadenfrei-rabatt-contract',
      name: 'SchadenfreiRabattContract',
      component: SchadenfreiRabattContract,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'schadenfrei-rabatt-contract/new',
      name: 'SchadenfreiRabattContractCreate',
      component: SchadenfreiRabattContractUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'schadenfrei-rabatt-contract/:schadenfreiRabattContractId/edit',
      name: 'SchadenfreiRabattContractEdit',
      component: SchadenfreiRabattContractUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'schadenfrei-rabatt-contract/:schadenfreiRabattContractId/view',
      name: 'SchadenfreiRabattContractView',
      component: SchadenfreiRabattContractDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'svs-agentur',
      name: 'SvsAgentur',
      component: SvsAgentur,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'svs-agentur/new',
      name: 'SvsAgenturCreate',
      component: SvsAgenturUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'svs-agentur/:svsAgenturId/edit',
      name: 'SvsAgenturEdit',
      component: SvsAgenturUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'svs-agentur/:svsAgenturId/view',
      name: 'SvsAgenturView',
      component: SvsAgenturDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'notification',
      name: 'Notification',
      component: Notification,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'notification/new',
      name: 'NotificationCreate',
      component: NotificationUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'notification/:notificationId/edit',
      name: 'NotificationEdit',
      component: NotificationUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'notification/:notificationId/view',
      name: 'NotificationView',
      component: NotificationDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'address',
      name: 'Address',
      component: Address,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'address/new',
      name: 'AddressCreate',
      component: AddressUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'address/:addressId/edit',
      name: 'AddressEdit',
      component: AddressUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'address/:addressId/view',
      name: 'AddressView',
      component: AddressDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: '/svs-makler',
      name: 'SvsMakler',
      component: SvsMakler,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: '/svs-makler/:svsMaklerId/view',
      name: 'SvsMaklerView',
      component: SvsMaklerDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: '/vertrags-nr',
      name: 'VertragsNr',
      component: VertragsNr,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/vertrags-nr/:vertragsNrId/view',
      name: 'VertragsNrView',
      component: VertragsNrDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/import-error',
      name: 'ImportError',
      component: ImportError,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/import-error/:importErrorId/view',
      name: 'ImportErrorView',
      component: ImportErrorDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/upload-error',
      name: 'UploadError',
      component: UploadError,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/upload-error/:uploadErrorId/view',
      name: 'UploadErrorView',
      component: UploadErrorDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/svs-makler',
      name: 'SvsMakler',
      component: SvsMakler,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/svs-makler/:svsMaklerId/view',
      name: 'SvsMaklerView',
      component: SvsMaklerDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'link-address',
      name: 'LinkAddress',
      component: LinkAddress,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'link-address/new',
      name: 'LinkAddressCreate',
      component: LinkAddressUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'link-address/:linkAddressId/edit',
      name: 'LinkAddressEdit',
      component: LinkAddressUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'link-address/:linkAddressId/view',
      name: 'LinkAddressView',
      component: LinkAddressDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'claim-check',
      name: 'ClaimCheck',
      component: ClaimCheck,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'claim-check/new',
      name: 'ClaimCheckCreate',
      component: ClaimCheckUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'claim-check/:claimCheckId/edit',
      name: 'ClaimCheckEdit',
      component: ClaimCheckUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'claim-check/:claimCheckId/view',
      name: 'ClaimCheckView',
      component: ClaimCheckDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'upgrade',
      name: 'Upgrade',
      component: Upgrade,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'upgrade/new',
      name: 'UpgradeCreate',
      component: UpgradeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'upgrade/:upgradeId/edit',
      name: 'UpgradeEdit',
      component: UpgradeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'upgrade/:upgradeId/view',
      name: 'UpgradeView',
      component: UpgradeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'message',
      name: 'Message',
      component: Message,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'message/new',
      name: 'MessageCreate',
      component: MessageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'message/:messageId/edit',
      name: 'MessageEdit',
      component: MessageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'message/:messageId/view',
      name: 'MessageView',
      component: MessageDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
    {
      path: '/import-round-summary/:importRoundId/view',
      name: 'ImportRoundDetailsExtended',
      component: ImportRoundDetailsExtended,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
  ],
};
